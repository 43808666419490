<template>
<v-container>
    <v-row>
        <v-col cols="12">
            <v-card class="py-6 px-6">
                <div style="width:80%; margin:0 auto">
                    <div class="convert-head">
                        <h1 class="text-center">Convert Your TXT</h1>
                    </div>
                    <div class="convert-type d-flex align-center">
                        <p class="text-center">
                            <v-icon class="mr-2">mdi-file-star</v-icon>Xero Payroll 
                        </p>
                        <v-icon class="mr-4 ml-4">mdi-arrow-right</v-icon>
                        <p class="text-center">
                            <v-icon class="mr-2">mdi-file-star</v-icon>Revolut EFT
                        </p>

                    </div>
                    <div class="mt-2 mb-2">
                        <v-row>
                            <v-col>
                                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="paymentDate" transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="paymentDate" label="Payment Date" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined></v-text-field>
                                    </template>
                                    <v-date-picker v-model="paymentDate" no-title scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="menu = false">
                                            Cancel
                                        </v-btn>
                                        <v-btn text color="primary" @click="$refs.menu.save(paymentDate)">
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col>
                                <v-text-field v-model="paymentRef" label="Payment Reference" outlined></v-text-field>

                            </v-col>
                            <v-col>
                                <v-text-field v-model="rbc" label="Recipient Bank Country" outlined></v-text-field>

                            </v-col>
                            <v-col>
                                <v-text-field v-model="Currency" label="Currency" outlined></v-text-field>

                            </v-col>
                            <v-col>
                                <v-select v-model="companyType" :items="['Company','Individual']" label="Recipient type" outlined></v-select>
                            </v-col>
                        </v-row>

                    </div>
                    <div v-if="!ConvertDone">
                        <vue-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions" @vdropzone-file-added="fileAdded">
                        </vue-dropzone>
                        <v-btn elevation="2" x-large color="#161d3c" dark style="margin:0 auto;display:block; margin-top:2rem;margin-bottom:2rem" @click="Convert" :disabled="loader">
                            <v-icon class="mr-4 text-center">mdi-swap-horizontal-bold</v-icon> Convert
                        </v-btn>
                        <v-progress-circular v-if="loader" :size="50" color="primary" indeterminate style="display: block; margin: 0 auto;"></v-progress-circular>
                    </div>
                    <v-btn elevation="2" x-large color="#161d3c" dark style="margin:0 auto;display:block; margin-top:2rem;margin-bottom:2rem" @click="ResetAll" v-if="ConvertDone">
                        <v-icon class="mr-4 text-center">mdi-swap-horizontal-bold</v-icon> New Convert
                    </v-btn>
                    <v-simple-table v-if="convertedFiles.length > 0">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        File Name
                                    </th>
                                    <th class="text-left">
                                        Download
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in convertedFiles" :key="index">
                                    <td>Converted - {{ item.data.info.name.replace("txt","") + "csv" }}</td>
                                    <td>
                                        <v-btn elevation="2" color="#161d3c" dark @click="DownloadFile(index)">Download</v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>

                </div>
            </v-card>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import {
    saveAs,
    encodeBase64
} from '@progress/kendo-file-saver';
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
export default {
    components: {
        vueDropzone: vue2Dropzone
    },
    data() {
        return {
            loader: false,
            inputFile: null,
            fileData: null,
            FinalResult: [],
            files_form: [],
            paymentDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu: false,
            convertedFiles: [],
            Currency: 'GBP',
            companyType: 'Company',
            rbc: 'GB',
            paymentRef: 'February invoices',
            ConvertDone: false,
            dropzoneOptions: {
                url: 'https://',
                autoProcessQueue: false,
                autoQueue: false,
                thumbnailWidth: 150,
                maxFilesize: 0.5,
                addRemoveLinks: true,
                acceptedFiles: '.txt',
                dictDefaultMessage: "<i aria-hidden='true' class='v-icon notranslate mdi mdi-cloud-upload theme--light'></i> Click Or Drag file here to Upload.",
                headers: {
                    'Access-Control-Allow-Origin': '*',
                },
                uploadMultiple: true,

            }
        }
    },
    watch: {
        inputFile(val) {
            let reader = new FileReader()
            reader.onload = (event) => {
                var data = {
                    content: event.target.result.split("\n"),
                    date: val.lastModifiedDate
                }
                this.fileData = data
                this.FinalizeConvert()

            }
            reader.readAsText(val)
        },

    },
    methods: {
        ResetAll() {
            this.loader = false;
            this.files_form = []
            this.convertedFiles = []
            this.ConvertDone = false
        },
        fileAdded(file) {
            this.files_form.push(file)
            console.log(this.files_form)
        },
        FileRender(file) {
            let reader = new FileReader()
            var data = {}
            data.info = file
            reader.onload = (event) => {
                data.content = event.target.result.split("\n");
                this.FinalizeConvert(data)
            }
            reader.readAsText(file)
        },
        async Convert() {
            this.loader = true;
            for (var i = 0; i < this.files_form.length; i++) {
                this.FileRender(this.files_form[i])

            }
            this.loader = false;
            this.ConvertDone = true;
        },

        convertDate(inputFormat) {
            function pad(s) {
                return (s < 10) ? '0' + s : s;
            }
            var d = new Date(inputFormat)
            return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('')
        },
        ltrim(str) {
            if (!str) return str;
            return str.replace(/^\s+/g, '');
        },
        rtrim(str) {
            if (!str) return str;
            return str.replace(/\s+$/g, '');
        },
        LineAnalysis(line) {
            var NWordIndex = line.indexOf('THE') + 1;

            var NSpaceIndex = line.indexOf('          ') + 1;
            var E = line.substr(0, 6);
            var F = line.substr(6, 8);
            var A = line.substr(17, 6);
            var B = line.substr(23, 8);
            var value = line.substr(36, 47).replace(/\//g, '');
            var Amount = parseInt(value.toString()).toString(); //41
            var Name = line.substr(NSpaceIndex, line.length - NSpaceIndex + 1);
            var New_sentance = [];
            New_sentance[0] = this.rtrim(this.ltrim(Name));;
            New_sentance[1] = this.companyType;
            New_sentance[2] = line.substr(6, 8);
            New_sentance[3] = line.substr(0, 6);
            New_sentance[4] = this.rbc;
            New_sentance[5] = this.Currency;
            New_sentance[6] = Amount.substr(0, Amount.length - 2) + '.' + Amount.substr(Amount.length - 2, Amount.length + 1);
            New_sentance[7] = this.paymentRef;

            return New_sentance;
        },
        async FinalizeConvert(data) {
            console.log(data)
            var FinalResult = [];
            var Header = [];
            Header[0] = 'Name';
            Header[1] = 'Recipient type';
            Header[2] = 'Account number'
            Header[3] = "Sort code";
            Header[4] = "Recipient bank country";
            Header[5] = "Currency";
            Header[6] = "Amount";
            Header[7] = "Payment reference";
            FinalResult.push(Header);
            for (var i = 4; i < data.content.length - 5; i++) {
                FinalResult.push(this.LineAnalysis(data.content[i]))
            }
            var result = "";
            for (var i = 0; i < FinalResult.length; i++) {
                result += FinalResult[i].join(",") + "\n";
            }

            const dataURI = "data:text/plain;base64," + encodeBase64(result);
            var fileData = {
                dataURI: dataURI,
                data: data,
                result: result
            }

            this.convertedFiles.push(fileData)

        },
        DownloadFile(index) {
            var file = this.convertedFiles[index]
            var newResult = file.result.split(",")
            console.log(newResult)
            var hiddenElement = document.createElement('a');
            hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(newResult);
            hiddenElement.target = '_blank';
            hiddenElement.download = "BBX- - " + file.data.info.name.replace("BAC", "") + "csv";
            hiddenElement.click();

            // saveAs(file.dataURI, "Converted - " + file.data.info.name);
        }

    }
}
</script>

<style lang="scss">
.convert-head {
    h1 {
        color: #161d3c;
        margin-bottom: 3rem;
        font-size: 3rem;
    }
}

.vue-dropzone {
    border: 4px dashed #161d3c;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;

}

.dz-message {
    color: #161d3c !important;
    font-weight: bold;

    i {
        font-size: 34px;
        color: #161d3c !important;
    }

}

.convert-type {
    align-content: center;
    justify-content: center;
    margin-bottom: 3rem;

    i {
        font-size: 34px;
        color: #161d3c !important;
    }

    p {
        margin: 0;
        font-size: 20px;
    }
}

.theme--light.v-data-table {
    border: 1px solid #000;
    margin: 3rem 0;
    padding: 1rem;
}

.theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
    font-size: 18px;
}
</style>
