<template>
    <revolut/>
    </template>
    
    <script>
    import revolut from '@/components/revolut.vue'
    export default {
        components:{
            revolut
        },
        data() {
            return {
               
    
                }
            }
     
    }
    </script>
    